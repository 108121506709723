import dynamic from 'next/dynamic';

import { PlatformID } from '@common/clients/api';

import styles from './PlatformLogo.module.scss';

const BbLogoMiniSvg = dynamic(() => import('@common/logos/bb-logo-mini.svg'));
const BiLogoDarkSvg = dynamic(() => import('@common/logos/bi-dark.svg'));
const BiLogoMiniSvg = dynamic(() => import('@common/logos/bi-logo-mini.svg'));
const BiLogoSvg = dynamic(() => import('@common/logos/bi.svg'));
const BrLogoMiniSvg = dynamic(() => import('@common/logos/br-logo-mini.svg'));
const BrLogoSvg = dynamic(() => import('@common/logos/br-dark.svg'));
const GpLogoMiniSvg = dynamic(() => import('@common/logos/gp-logo-mini.svg'));
const GpLogoSvg = dynamic(() => import('@common/logos/gp.svg'));
const MhLogoDarkSvg = dynamic(() => import('@common/logos/mh-dark.svg'));
const MhLogoMiniSvg = dynamic(() => import('@common/logos/mh-logo-mini.svg'));
const MhLogoSvg = dynamic(() => import('@common/logos/mh.svg'));
const RwLogoDarkSvg = dynamic(() => import('@common/logos/rw-dark.svg'));
const RwLogoMiniSvg = dynamic(() => import('@common/logos/rw-logo-mini.svg'));
const RwLogoSvg = dynamic(() => import('@common/logos/rw.svg'));
const ViLogoMiniSvg = dynamic(() => import('@common/logos/vi-logo-mini.svg'));
const ViLogoSvg = dynamic(() => import('@common/logos/vi.svg'));
const VnLogoDarkSvg = dynamic(() => import('@common/logos/vn-dark.svg'));
const VnLogoMiniSvg = dynamic(() => import('@common/logos/vn-logo-mini.svg'));
const VnLogoSvg = dynamic(() => import('@common/logos/vn.svg'));
const VpLogoDarkSvg = dynamic(() => import('@common/logos/vp-dark.svg'));
const VpLogoSvg = dynamic(() => import('@common/logos/vp.svg'));
const WhLogoDarkSvg = dynamic(() => import('@common/logos/wh-dark.svg'));
const WhLogoMiniSvg = dynamic(() => import('@common/logos/wh-logo-mini.svg'));
const WhLogoSvg = dynamic(() => import('@common/logos/wh.svg'));

export enum MissingPlatformID {
    BB = 'bb',
    SP = 'sp',
    VPBE = 'vpbe',
}

const getLogo = ({ platformID, isDarkBackground }: Props) => {
    switch (platformID) {
        case PlatformID.BI:
            return !isDarkBackground ? <BiLogoSvg /> : <BiLogoDarkSvg />;
        case PlatformID.BR:
            return <BrLogoSvg />;
        case PlatformID.GP:
            return <GpLogoSvg />;
        case PlatformID.MH:
            return !isDarkBackground ? <MhLogoSvg /> : <MhLogoDarkSvg />;
        case PlatformID.RW:
            return !isDarkBackground ? <RwLogoSvg /> : <RwLogoDarkSvg />;
        case PlatformID.VN:
            return !isDarkBackground ? <VnLogoSvg /> : <VnLogoDarkSvg />;
        case PlatformID.VP:
            return !isDarkBackground ? <VpLogoSvg /> : <VpLogoDarkSvg />;
        case PlatformID.VI:
            return <ViLogoSvg />;
        case PlatformID.WH:
            return !isDarkBackground ? <WhLogoSvg /> : <WhLogoDarkSvg />;
        default:
            return null;
    }
};

const getMiniLogo = ({ platformID }: Props) => {
    switch (platformID) {
        case PlatformID.BI:
            return <BiLogoMiniSvg />;
        case PlatformID.BR:
            return <BrLogoMiniSvg />;
        case PlatformID.GP:
            return <GpLogoMiniSvg />;
        case PlatformID.MH:
            return <MhLogoMiniSvg />;
        case PlatformID.RW:
            return <RwLogoMiniSvg />;
        case PlatformID.VI:
            return <ViLogoMiniSvg />;
        case PlatformID.VN:
            return <VnLogoMiniSvg />;
        case PlatformID.VP:
            return <VpLogoSvg />;
        case PlatformID.WH:
            return <WhLogoMiniSvg />;
        case MissingPlatformID.BB:
            return <BbLogoMiniSvg />;
        default:
            return null;
    }
};

export enum PositionVariant {
    FOOTER = 'footer',
    FOOTER_MINI = 'footer-mini',
    HEADER = 'header',
}

interface Props {
    classes?: string;
    extraPadding?: boolean;
    positionVariant?: PositionVariant;
    isDarkBackground?: boolean;
    platformID: PlatformID | MissingPlatformID;
    hasBackground?: boolean;
}

export const PlatformLogo = ({
    classes,
    extraPadding,
    positionVariant,
    platformID,
    hasBackground,
    isDarkBackground,
}: Props) => (
    <div
        className={`
        ${styles.PlatformLogo}
        ${positionVariant ? styles[positionVariant] : ''}
        ${extraPadding ? styles['extra-padding'] : ''}
        ${hasBackground ? styles['has-background'] : ''}
        ${classes || ''}`}
    >
        {positionVariant === PositionVariant.FOOTER_MINI
            ? getMiniLogo({ platformID })
            : getLogo({
                  platformID,
                  isDarkBackground,
              })}
    </div>
);
